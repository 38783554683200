'use strict';

$(document).ready(function () {
  $('[data-href]').link();
  $('[data-dynamic-content]').dynamicContent();
  $('[data-toggle="tooltip"]').tooltip();
  $('[data-toggle="selectpicker"]').selectpicker({
    liveSearch: true
  });

  $('.js-datetimepicker').each(function () {
    $(this).datetimepicker({
      format: 'DD.MM.YYYY',
      showClear: true,
      useCurrent: false
    });

  });

});
